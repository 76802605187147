"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertCharlyQueryPatient2Factoring = exports.getHashes = exports.createMd5Hash = exports.isInland = exports.convertRgAn = exports.convertCharlyHKPStatus = exports.isSolutioTemplateRefFile = exports.isHintergrungRefFile = void 0;
const lodash_1 = require("lodash");
const types_1 = require("../../../types");
const rose_dayjs_1 = require("../rose-dayjs");
function isHintergrungRefFile(refFile) {
    return !!refFile?.startsWith('Hintergrund/');
}
exports.isHintergrungRefFile = isHintergrungRefFile;
function isSolutioTemplateRefFile(refFile) {
    return !!refFile?.startsWith('SolutioTemplates/');
}
exports.isSolutioTemplateRefFile = isSolutioTemplateRefFile;
function convertCharlyHKPStatus(s) {
    switch (s) {
        case 'EINGABE':
            return types_1.HKPStatusType.EINGABE;
        case 'ÜBERARBEITEN':
            return types_1.HKPStatusType.ÜBERARBEITEN;
        case 'IN_ARBEIT':
            return types_1.HKPStatusType.UNKNOWN; // TODO getHKPStatus IN_ARBEIT
        case 'HKP_AN_KASSE':
            return types_1.HKPStatusType.UNKNOWN; // TODO getHKPStatus HKP_AN_KASSE
        case 'GENEHMIGT':
            return types_1.HKPStatusType.GENEHMIGT;
        case 'GENEHMIGT_OHNE_TERMIN':
            return types_1.HKPStatusType.GENEHMIGT;
        case 'OHNE_GENEHMIGUNG':
            return types_1.HKPStatusType.UNKNOWN; // TODO getHKPStatus OHNE_GENEHMIGUNG
        case 'ABGELEHNT':
            return types_1.HKPStatusType.ABGELEHNT;
        case 'DURCHGEFÜHRT':
            return types_1.HKPStatusType.DURCHGEFUEHRT;
        case 'EINGEGLIEDERT':
            return types_1.HKPStatusType.UNKNOWN; // TODO getHKPStatus EINGEGLIEDERT
        case 'VERWORFEN':
            return types_1.HKPStatusType.VERWORFEN;
    }
    return types_1.HKPStatusType.UNKNOWN;
}
exports.convertCharlyHKPStatus = convertCharlyHKPStatus;
function convertRgAn(s) {
    switch (s) {
        case 'VERSICHERTER':
            return types_1.RECHNUNGSEMPFAENGER_TYPE.VERSICHERTER;
        case 'ABWEICHENDER_RECHNUNGSEMPFÄNGER':
            return types_1.RECHNUNGSEMPFAENGER_TYPE.RECHNUNGSEMPFAENGER;
        case 'UNFALLVERSICHERUNG_RECHNUNGSEMPFÄNGER':
            return types_1.RECHNUNGSEMPFAENGER_TYPE.UNFALLVERSICHERUNG;
        case 'PATIENT':
        default:
            return types_1.RECHNUNGSEMPFAENGER_TYPE.PATIENT;
    }
}
exports.convertRgAn = convertRgAn;
function isInland(land) {
    return !land || land.toLowerCase().startsWith('deu') || land.toLowerCase() === 'de' || land.toLowerCase() === 'd';
}
exports.isInland = isInland;
async function createMd5Hash(s, salt) {
    const CryptoES = (await Promise.resolve().then(() => __importStar(require('crypto-es')))).default;
    if (s) {
        const s_clean = s.replace(/ä/gi, 'ae').replace(/ö/gi, 'oe').replace(/ü/gi, 'ue').replace(/ß/gi, 'ss').toLowerCase();
        return `${salt}$${CryptoES.MD5(s_clean + salt).toString()}`;
    }
    else {
        return `${salt}$${CryptoES.MD5(salt).toString()}`;
    }
}
exports.createMd5Hash = createMd5Hash;
async function getHashes(fp) {
    const p = {};
    if (fp.rechnungsempfaenger) {
        p.ref_p_simple = await createMd5Hash(fp.name + fp.vorname + fp.geburtsdatum, fp.extid);
        p.ref_p_full = await createMd5Hash(fp.name + fp.vorname + fp.geburtsdatum + fp.strasse + fp.plz + fp.ort, fp.extid);
        p.ref_re_simple = await createMd5Hash(fp.rechnungsempfaenger.name + fp.rechnungsempfaenger.vorname + fp.rechnungsempfaenger.geburtsdatum, fp.extid);
        p.ref_re_full = await createMd5Hash(fp.rechnungsempfaenger.name +
            fp.rechnungsempfaenger.vorname +
            fp.rechnungsempfaenger.geburtsdatum +
            fp.rechnungsempfaenger.strasse +
            fp.rechnungsempfaenger.plz +
            fp.rechnungsempfaenger.ort, fp.extid);
        p.ref_p_re_simple = await createMd5Hash(fp.name +
            fp.vorname +
            fp.geburtsdatum +
            fp.rechnungsempfaenger.name +
            fp.rechnungsempfaenger.vorname +
            fp.rechnungsempfaenger.geburtsdatum, fp.extid);
        p.ref_p_re_full = await createMd5Hash(fp.name +
            fp.vorname +
            fp.geburtsdatum +
            fp.strasse +
            fp.plz +
            fp.ort +
            fp.rechnungsempfaenger.name +
            fp.rechnungsempfaenger.vorname +
            fp.rechnungsempfaenger.geburtsdatum +
            fp.rechnungsempfaenger.strasse +
            fp.rechnungsempfaenger.plz +
            fp.rechnungsempfaenger.ort, fp.extid);
        p.fieldhashes = {
            patient: {
                name: await createMd5Hash(fp.name, fp.extid),
                vorname: await createMd5Hash(fp.vorname, fp.extid),
                geburtsdatum: await createMd5Hash(fp.geburtsdatum, fp.extid),
                strasse: await createMd5Hash(fp.strasse, fp.extid),
                plz: await createMd5Hash(fp.plz, fp.extid),
                ort: await createMd5Hash(fp.ort, fp.extid),
            },
            re: {
                name: await createMd5Hash(fp.rechnungsempfaenger.name, fp.extid),
                vorname: await createMd5Hash(fp.rechnungsempfaenger.vorname, fp.extid),
                geburtsdatum: await createMd5Hash(fp.rechnungsempfaenger.geburtsdatum, fp.extid),
                strasse: await createMd5Hash(fp.rechnungsempfaenger.strasse, fp.extid),
                plz: await createMd5Hash(fp.rechnungsempfaenger.plz, fp.extid),
                ort: await createMd5Hash(fp.rechnungsempfaenger.ort, fp.extid),
            },
        };
    }
    return p;
}
exports.getHashes = getHashes;
function convertCharlyQueryPatient2Factoring(p) {
    return {
        extid: p.stammdaten.id + '',
        vorname: p.stammdaten.vorname,
        name: p.stammdaten.name,
        namenszusatz: p.stammdaten.vorsatzwort,
        titel: p.stammdaten.titel,
        strasse: p.stammdaten.strasse,
        plz: p.stammdaten.plz + '',
        ort: p.stammdaten.ort,
        geburtsdatum: p.stammdaten.geburtstag,
        email: (0, lodash_1.first)(p.stammdaten.emails) || '',
        geschlecht: p.stammdaten.geschlecht === 'M' ? 'M' : p.stammdaten.geschlecht === 'W' ? 'W' : 'U',
        patientseit: p.stammdaten.patient_seit,
        beruf: p.stammdaten.beruf,
        arbeitgeber: p.stammdaten.arbeitgeber,
        versichertbei: p.stammdaten.krankenkassenname,
        versicherungsart: p.stammdaten.versicherungsart,
        inland: isInland(p.stammdaten.land_kuerzel),
        land: p.stammdaten.land_kuerzel,
        stammbehandler: p.stammdaten.stammbehandler_id,
        rechnungsempfaenger: {
            extid: p.rg_empfaenger.id + '',
            vorname: p.rg_empfaenger.vorname,
            name: p.rg_empfaenger.name,
            // namenszusatz: p.rg_empfaenger.vorsatzwort, // TODO charly
            strasse: p.rg_empfaenger.strasse,
            plz: p.rg_empfaenger.plz + '',
            ort: p.rg_empfaenger.ort,
            geburtsdatum: p.rg_empfaenger.geburtstag,
            email: '',
            geschlecht: p.rg_empfaenger.geschlecht === 'M' ? 'M' : p.rg_empfaenger.geschlecht === 'W' ? 'W' : 'U',
            land: p.rg_empfaenger.land_kuerzel,
        },
        hkps: (0, lodash_1.map)(p.hkps, x => ({
            id: x.id + '',
            datum: (0, rose_dayjs_1.roseDayjs)(x.datum).toDate(),
            status: convertCharlyHKPStatus(x.mikro_status),
            gesamt: x.gesamt_betrag,
            versichertenanteil: x.versichertenanteil_betrag,
            rest: x.bb_leistungen_nichtabgerechnet_betrag,
            leistungen: x.bb_leistungen_abgerechnet_betrag,
        })),
        erbrachteLeistungen: p.bb_leistungen
            ? p.bb_leistungen.nicht_abgerechnet_goz +
                p.bb_leistungen.nicht_abgerechnet_sonstige +
                p.bb_leistungen.nicht_abgerechnet_material +
                p.bb_leistungen.nicht_abgerechnet_labor
            : -1,
        rgan: convertRgAn(p.rg_empfaenger.typ),
        rganid: p.rg_empfaenger.id + '',
        rginland: isInland(p.rg_empfaenger.land_kuerzel),
        zuletztAbgerechnetGoz: (0, rose_dayjs_1.roseDayjs)(p.bb_leistungen_datum.zuletzt_abgerechnet_goz).toDate(),
        zuletztNichtAbgerechnetGoz: (0, rose_dayjs_1.roseDayjs)(p.bb_leistungen_datum.zuletzt_nicht_abgerechnet_goz).toDate(),
    };
}
exports.convertCharlyQueryPatient2Factoring = convertCharlyQueryPatient2Factoring;
